import "index.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

// import Alpinejs
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

import "swiper.js"

import "lite-youtube-embed"
